.area-foot {
  background: transparent;
  &>.container {
    border: $nav-header-border-size solid $kz-color-blue;
    padding-top: 15px;
    padding-bottom: 0;
  }
  .head.headline {
    border-bottom: none;
    margin-top: .5em;
    margin-bottom: 1.5em;
    & h2, & h3, & h4 {
      color: $kz-color-blue !important;
      border-color: $kz-color-blue;
      border-bottom: $nav-header-border-size solid $kz-color-blue;
      font-size: 1.7rem;
      &::before {
  			background: url("../kirchenzeitung/img/arrow_bottom_blue.svg");
      }
    }
  }

  .head h2, .head h3, .head h4,
  .ap-contact .fn {
    color: $kz-color-blue !important;
    font-size: 1.5rem;
    line-height: 1.25;
    font-weight: 800;
  }

  .fa-before,
  .fa {
    color: $kz-color-blue !important;
  }
  // facebook
  .kz-social {
    list-style: none;
    padding-left: 0;
    li {
      margin-bottom: 0.5em;
      a {
        .fa {
          background: $text-color;
          color: #fff !important;
          padding: .4em .4em 0;
          font-size: 2.5rem;
          margin-right: 0.25em;
          &::before {
            margin-bottom: -3px;
            margin-top: 2px;
            display: block;
          }
        }
        &:hover, &:focus {
          .fa {
            background: $kz-color-blue;
          }
        }
      }

    }
  }
  .subfoot {
    margin-top: 4rem;
  }
}
