// mixins
%arrowimage {
  content: "";
  display: inline-block;
  background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-size: contain;
}
%arrowimage_bottom {
  margin-right: 0.5em;
  height: 0.75em;
  width: 1.5em;
}
%arrowimage_right {
  height: 1em;
  width: 0.5em;
}

@mixin arrow_bottom_red {
  @extend %arrowimage;
  @extend %arrowimage_bottom;
  background: url("../kirchenzeitung/img/arrow_bottom_red.svg");
}

@mixin arrow_bottom_blue {
  @extend %arrowimage;
  @extend %arrowimage_bottom;
  background: url("../kirchenzeitung/img/arrow_bottom_blue.svg");
}

@mixin arrow_right_red {
  @extend %arrowimage;
  @extend %arrowimage_right;
  background: url("../kirchenzeitung/img/arrow_right_red.svg");
}
@mixin arrow_right_white {
  @extend %arrowimage;
  @extend %arrowimage_right;
  background: url("../kirchenzeitung/img/arrow_right_white.svg");
}

// imports
@import "assets/header";
@import "assets/footer";
@import "assets/commons";
@import "assets/page_home";
@import "assets/comp_contact";
@import "assets/comp_marginalbox";
