.headline {
  border-bottom: $nav-header-border-size solid $kz-color-red;
	margin-top: 4em;
	margin-bottom: 1em;
  & h1, h2, & h3, & h4 {
    border-bottom: none;
    text-transform: uppercase;
    &::before {
      @include arrow_bottom_red;
    }
  }
}

.ap-list-content .ap-list-tile .teaser-body .teaser-title {
  min-height: 0 !important;
}

.sidebar-nav {
  .list-group-item {
    border: none;
    font-size: $nav-header-font-size;
    font-weight: $nav-header-font-weight;
    line-height: $nav-header-line-height;
    .nav-label {
      display: inline-block;
      color: $nav-header-text;
      text-transform: $nav-header-text-transform;
      &::before {
        @include arrow_right_red;
        margin-right: 0.5em;
      }
      &:hover, &:focus {
        background: initial;
      }
    }
    &:hover, &:focus,
    &.currentpage {
      background: initial !important;
      &::before {
        @include arrow_right_red;
      }
      .nav-label {
        color: $kz-color-red;
        font-weight: 600;
      }
    }
  }
}


.kz_standpoint.ap-teaser {
  background: #d4ddee;
  padding: 4rem;
  margin-top: 10rem;
  margin-bottom: 15rem;
  display: flex;
  .teaser-visual {
    order: 2;
    margin-top: -6em;
    padding: 0 0 0 1em;
    .ap-image {
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    }
  }
  .teaser-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0;
    .fully {
      display: flex;
      flex-direction: column;
      .teaser-date {
        order: -1;
        margin-bottom: 1em;
      }
      .teaser-title {
        font-size: 2rem;
      }
    }
  }
  .teaser-btn {
    margin-top: auto;
    &:after {
      @include arrow_right_white;
      margin-left: 0.5em;
      margin-bottom: -0.2em;
    }
  }
}
