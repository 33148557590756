.area-head {
  .mainheader {
    .head-visual {
      .ap-linksequence-hf {
        width: auto;
      }
      .kz_headlinks {
        float: left;
      }
      .kz_headbistumlinks {
        float: right;
      }
      .image-only {
        margin-top: 15px;
        margin-bottom: 0;
      }
    }
    .head-navbar {
      border-bottom: none;
      .navbar-nav {
        border-bottom: $nav-header-border-bottom;
        #searchButtonHeader.dropdown > a {
          padding: $nav-header-padding;
        }
      }
    }
  }
}
