.ap-contact {
  .text-box {
    margin-top: 1em;
    > .tablerow > * {
      padding-bottom: 0.5em;
    }
    .n { // name

    }
    .title {
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 600;
      margin: 0.75em 0;
    }
    .phone {
    }
    .mail {

    }
  }
}
