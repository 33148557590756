// Box für Marginalspalte (u.a. aktuelle Ausgabe)
.kz_marginalbox {
  &,
  &_border {
    margin-top: 10rem;
    background: #d4ddee;
    padding: 3rem;
    .ap-image-section {
      margin-top: -8rem;
      padding-left: 10%;
      margin-bottom: 20%;
      .ap-image {
        border: 1px solid #818284;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      }
    }
    .head * {
      color: #000;
    }
    .headline {
      margin-top: 0;
      border-color: $kz-color-blue;
      * {
        color: $kz-color-blue;
        &:before {
          @include arrow_bottom_blue;
        }
      }
    }
    .text {
      color: #000;
      h1, h2, h3, h4, h5, h6 {
        font-size: $font-content-size;
        font-weight: 800;
        margin-top: 1.5em;
        color: #000;
        strong {
          font-weight: 800;
        }
      }
    }
    & + .kz_marginalbox,
    & + .kz_marginalbox_border {
      margin-top: 5rem;
    }
  }
  &_border {
    background: transparent;
    border: 1px solid $kz-color-blue;
    a, a:active, a:focus, a:hover {
      color: $kz-color-blue;
    }
  }
}
