.ap-slick-slider {
  border: $nav-header-border-size solid $text-color;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  .caption {
    strong {
      text-transform: uppercase;
    }
  }
  .subcaption {
    small {
    }
  }
}

.kz_home_newslist {
  .list-entry  {
    .ap-teaser {
      .teaser-visual {
        margin-bottom: 1.5rem;
      }
      .teaser-body {
        .teaser-title {
          margin-bottom: 0.75rem;
        }
        .teaser-headline {}
      }
      .teaser-link {}
    }
  }
}
